*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-0 {
  left: 0;
}

.left-8 {
  left: 2rem;
}

.right-0 {
  right: 0;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-1\/6 {
  top: 16.6667%;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.float-right {
  float: right;
}

.m-1 {
  margin: .25rem;
}

.m-1\.5 {
  margin: .375rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-48 {
  width: 12rem;
  height: 12rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-full {
  height: 100%;
}

.max-h-48 {
  max-height: 12rem;
}

.w-0 {
  width: 0;
}

.w-0\% {
  width: 0%;
}

.w-1 {
  width: .25rem;
}

.w-1\% {
  width: 1%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\% {
  width: 10%;
}

.w-100\% {
  width: 100%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\% {
  width: 11%;
}

.w-12 {
  width: 3rem;
}

.w-12\% {
  width: 12%;
}

.w-13\% {
  width: 13%;
}

.w-14 {
  width: 3.5rem;
}

.w-14\% {
  width: 14%;
}

.w-15\% {
  width: 15%;
}

.w-16 {
  width: 4rem;
}

.w-16\% {
  width: 16%;
}

.w-17\% {
  width: 17%;
}

.w-18\% {
  width: 18%;
}

.w-19\% {
  width: 19%;
}

.w-2 {
  width: .5rem;
}

.w-2\% {
  width: 2%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-20\% {
  width: 20%;
}

.w-21\% {
  width: 21%;
}

.w-22\% {
  width: 22%;
}

.w-23\% {
  width: 23%;
}

.w-24 {
  width: 6rem;
}

.w-24\% {
  width: 24%;
}

.w-25\% {
  width: 25%;
}

.w-26\% {
  width: 26%;
}

.w-27\% {
  width: 27%;
}

.w-28 {
  width: 7rem;
}

.w-28\% {
  width: 28%;
}

.w-29\% {
  width: 29%;
}

.w-3 {
  width: .75rem;
}

.w-3\% {
  width: 3%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-30\% {
  width: 30%;
}

.w-31\% {
  width: 31%;
}

.w-32 {
  width: 8rem;
}

.w-32\% {
  width: 32%;
}

.w-33\% {
  width: 33%;
}

.w-34\% {
  width: 34%;
}

.w-35\% {
  width: 35%;
}

.w-36 {
  width: 9rem;
}

.w-36\% {
  width: 36%;
}

.w-37\% {
  width: 37%;
}

.w-38\% {
  width: 38%;
}

.w-39\% {
  width: 39%;
}

.w-4 {
  width: 1rem;
}

.w-4\% {
  width: 4%;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-40\% {
  width: 40%;
}

.w-41\% {
  width: 41%;
}

.w-42\% {
  width: 42%;
}

.w-43\% {
  width: 43%;
}

.w-44 {
  width: 11rem;
}

.w-44\% {
  width: 44%;
}

.w-45\% {
  width: 45%;
}

.w-46\% {
  width: 46%;
}

.w-47\% {
  width: 47%;
}

.w-48 {
  width: 12rem;
}

.w-48\% {
  width: 48%;
}

.w-49\% {
  width: 49%;
}

.w-5 {
  width: 1.25rem;
}

.w-5\% {
  width: 5%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-50\% {
  width: 50%;
}

.w-51\% {
  width: 51%;
}

.w-52 {
  width: 13rem;
}

.w-52\% {
  width: 52%;
}

.w-53\% {
  width: 53%;
}

.w-54\% {
  width: 54%;
}

.w-55\% {
  width: 55%;
}

.w-56 {
  width: 14rem;
}

.w-56\% {
  width: 56%;
}

.w-57\% {
  width: 57%;
}

.w-58\% {
  width: 58%;
}

.w-59\% {
  width: 59%;
}

.w-6 {
  width: 1.5rem;
}

.w-6\% {
  width: 6%;
}

.w-60 {
  width: 15rem;
}

.w-60\% {
  width: 60%;
}

.w-61\% {
  width: 61%;
}

.w-62\% {
  width: 62%;
}

.w-63\% {
  width: 63%;
}

.w-64 {
  width: 16rem;
}

.w-64\% {
  width: 64%;
}

.w-65\% {
  width: 65%;
}

.w-66\% {
  width: 66%;
}

.w-67\% {
  width: 67%;
}

.w-68\% {
  width: 68%;
}

.w-69\% {
  width: 69%;
}

.w-7 {
  width: 1.75rem;
}

.w-7\% {
  width: 7%;
}

.w-70\% {
  width: 70%;
}

.w-71\% {
  width: 71%;
}

.w-72 {
  width: 18rem;
}

.w-72\% {
  width: 72%;
}

.w-73\% {
  width: 73%;
}

.w-74\% {
  width: 74%;
}

.w-75\% {
  width: 75%;
}

.w-76\% {
  width: 76%;
}

.w-77\% {
  width: 77%;
}

.w-78\% {
  width: 78%;
}

.w-79\% {
  width: 79%;
}

.w-8 {
  width: 2rem;
}

.w-8\% {
  width: 8%;
}

.w-80 {
  width: 20rem;
}

.w-80\% {
  width: 80%;
}

.w-81\% {
  width: 81%;
}

.w-82\% {
  width: 82%;
}

.w-83\% {
  width: 83%;
}

.w-84\% {
  width: 84%;
}

.w-85\% {
  width: 85%;
}

.w-86\% {
  width: 86%;
}

.w-87\% {
  width: 87%;
}

.w-88\% {
  width: 88%;
}

.w-89\% {
  width: 89%;
}

.w-9 {
  width: 2.25rem;
}

.w-9\% {
  width: 9%;
}

.w-90\% {
  width: 90%;
}

.w-91\% {
  width: 91%;
}

.w-92\% {
  width: 92%;
}

.w-93\% {
  width: 93%;
}

.w-94\% {
  width: 94%;
}

.w-95\% {
  width: 95%;
}

.w-96 {
  width: 24rem;
}

.w-96\% {
  width: 96%;
}

.w-97\% {
  width: 97%;
}

.w-98\% {
  width: 98%;
}

.w-99\% {
  width: 99%;
}

.w-full {
  width: 100%;
}

.min-w-28 {
  min-width: 7rem;
}

.origin-top-left {
  transform-origin: 0 0;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-pointer {
  cursor: pointer;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-1 {
  -moz-column-gap: .25rem;
  column-gap: .25rem;
}

.gap-x-1\.5 {
  -moz-column-gap: .375rem;
  column-gap: .375rem;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-md {
  border-radius: .375rem;
}

.border-2 {
  border-width: 2px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.data-\[closed\]\:scale-95[data-closed] {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:transform[data-closed] {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:opacity-0[data-closed] {
  opacity: 0;
}

.data-\[enter\]\:duration-100[data-enter] {
  transition-duration: .1s;
}

.data-\[leave\]\:duration-75[data-leave] {
  transition-duration: 75ms;
}

.data-\[enter\]\:ease-out[data-enter] {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.data-\[leave\]\:ease-in[data-leave] {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

@media (width >= 768px) {
  .md\:w-3\/5 {
    width: 60%;
  }
}

@media (width >= 1536px) {
  .\32 xl\:relative {
    position: relative;
  }

  .\32 xl\:float-right {
    float: right;
  }

  .\32 xl\:flex {
    display: flex;
  }

  .\32 xl\:w-1\/2 {
    width: 50%;
  }

  .\32 xl\:w-5\/6 {
    width: 83.3333%;
  }

  .\32 xl\:max-w-7xl {
    max-width: 80rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-white {
    --tw-border-opacity: 1;
    border-color: rgb(255 255 255 / var(--tw-border-opacity));
  }

  .dark\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgb(107 114 128 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:bg-slate-300 {
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
  }

  .dark\:bg-slate-400 {
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
  }

  .dark\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
/*# sourceMappingURL=index.b6231b28.css.map */
